import ArrowRightIcon from "@/assets/icons/arow-right-square.svg";
import CustomSourceFlowText from "@/components/Shared/CustomSourceflowText";
import FooterLinks from "./components/FooterLinks";
import LanguageDropdown from "@/components/Shared/LanguagesDropdown";
import Link from "next/link";
import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import { FOOTER_LOGOS, FOOTER_WEBSITE } from "@/constants";
import usePolicies from "@/hooks/usePolicies";
import meduza from "@/assets/homepage/meduza.png";
import useLocale from "@/hooks/useLocale";

export default function Footer() {
  const locale = useLocale();
  const policies = usePolicies();

  return (
    <footer className="bg-pink py-4 md:py-[60px] relative z-[100]">
      <div className="container flex flex-col">
        <div className="flex flex-col gap-5 md:gap-[72px]">
          <div className="flex flex-col gap-[72px] col-span-1">
            <div className="flex gap-4 md:gap-0 justify-between items-center sm:items-start flex-col sm:flex-row">
              <div className="flex flex-col gap-3 items-center md:items-start">
                <p className="text-xs lg:text-base text-purple w-max">
                  You are viewing content optimised for:
                </p>
                <LanguageDropdown className="bg-purple-light flex justify-center items-center w-[161px] h-[49px] group/item hover:bg-white cursor-pointer text-white text-xl hover:text-purple" />
              </div>
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4 lg:gap-5">
                <p className="text-xs lg:text-base/[30px] text-purple">
                  Connect with us
                </p>
                <div className="flex flex-wrap justify-center sm:flex-nowrap">
                  {FOOTER_WEBSITE.map(({ Icon, id, path, name }) => (
                    <Link
                      target="_blank"
                      href={path}
                      key={id}
                      className="flex items-center justify-center w-max last:pl-5"
                      aria-label={name}
                    >
                      <Icon className="hover:fill-white" />
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between gap-6 lg:gap-[70px]">
            <FooterLinks links={policies} />
            <div className="relative flex justify-center lg:-mt-24 w-full lg:w-full lg:h-[640px]">
              <SourceFlowImage
                src={meduza ?? ""}
                size="1233x586"
                alt="team"
                className="w-full h-full object-cover"
              />
              <div className="absolute lg:left-[49px] top-10 max-w-[420px] text-center lg:text-left">
                <a
                  href="https://ecologi.com/lhi"
                  target="_blank"
                  className="relative text-white text-base md:text-3xl lg:text-[40px]/[48px]"
                >
                  We’re always looking out for the planet. Learn about our
                  impact <ArrowRightIcon className="inline" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 md:pt-12 flex flex-col gap-5 md:gap-0 md:flex-row justify-between md:border-t md:border-t-purple">
          <div className="max-w-[430px] md:pe-5">
            <p className="text-xs md:text-base/[27px] font-semibold text-purple">
              <CustomSourceFlowText
                title="Multi-industry talent solutions."
                path="dynamic1.footer.header1"
                locale={locale}
              />
            </p>
            <p className="text-xs md:text-base/[24px] text-purple mt-1">
              <CustomSourceFlowText
                title="We are proud to be a part of LHi Group Ltd, a group company consisting of five specialist, non-competing recruitment brands."
                path="dynamic1.footer.text"
                locale={locale}
              />
            </p>
          </div>
          <div className="footer-brands grid grid-cols-3 sm:flex gap-10">
            {FOOTER_LOGOS.map((elem) => (
              <a
                href={elem.link}
                key={elem.id}
                target="_blank"
                className={`${
                  elem.id === 1
                    ? "md:border-r gap-[6px] md:border-r-purple md:pr-10"
                    : "gap-5"
                }`}
              >
                <div className="image-container mb-4">
                  <img src={elem.url} alt="logo" className="brands-logo" />
                </div>
                <p
                  className={`text-[8px] text-purple text-center ${elem.width} m-auto`}
                >
                  {elem.title}
                </p>
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}
