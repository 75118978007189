import Link from "next/link";
import { usePathname } from "next/navigation";
import useContacts from "@/hooks/useContacts";
import localizePath from "@/functions/localizePath";
import useLocale from "@/hooks/useLocale";
import { title } from "process";

export default function FooterLinks({ links }) {
  const locale = useLocale();
  const path = usePathname();
  const contacts = useContacts();
  const legal = links.map((elem) => ({
    path: localizePath(`/${elem.url_slug}`, locale),
    title: elem.title,
  }));
  const contactLinks = contacts.map((elem) => ({
    path: localizePath(`/contact/#${elem.url_slug}`, locale),
    title: elem.city,
  }));
  const FOOTER_LINKS = {
    Explore: [
      { title: "About", path: "/about" },
      { title: "Candidates", path: "/candidates" },
      { title: "Clients", path: "/clients" },
      { title: "Jobs", path: "/jobs" },
      { title: "Team", path: "/team" },
      { title: "Join us", path: "/join-us" },
      { title: "Events", path: "/events" },
    ],
    Legal: [
      {title: "Privacy Policy", path: "/legal/privacy-policy"},
      {title: "Cookie Policy", path: "/legal/cookie-policy"},
      {title: "Modern Slavery Policy", path: "/legal/modern-slavery-policy"}
    ],
    Content: [
      { title: "Blog", path: "/newsroom" },
      { title: "News", path: "/newsroom/news" },
      { title: "Podcast", path: "/newsroom/podcasts" },
      { title: "Newsletter", path: "/newsroom/newsletter" },
    ],
    Contact: contactLinks,
  };

  return (
    <div className="flex gap-0 sm:gap-20 lg:gap-10 justify-between lg:justify-start">
      <div className="flex justify-between w-1/2 flex-col sm:flex-row lg:justify-start lg:flex-col gap-12">
        {Object.entries(FOOTER_LINKS)
          .slice(0, 2)
          .map(([key, val], idx) => (
            <div
              key={key}
              className={`flex flex-col gap-4 ${idx === 1 ?? idx === 3 ? "row-span-1" : "row-span-2"}`}
            >
              <h2 className="text-purple font-medium text-base md:text-xl/[38px]">
                {key}
              </h2>
              <div className="flex flex-col ">
                {val.map((elem, id) => (
                  <Link
                    key={id}
                    target="_self"
                    href={localizePath(elem.path, locale)}
                    className={`${path.includes(elem.path) ? "text-white" : "text-purple"} mb-[3px] xl:whitespace-nowrap font-normal text-xs/[25px] md:text-base/[30px] hover:text-white`}
                  >
                    {elem.title}
                  </Link>
                ))}
              </div>
            </div>
          ))}
      </div>
      <div className="flex justify-between w-1/2 flex-col sm:flex-row lg:justify-start lg:flex-col gap-12">
        {Object.entries(FOOTER_LINKS)
          .slice(2, 5)
          .map(([key, val], idx) => (
            <div
              key={key}
              className={`flex flex-col gap-4 ${idx === 1 ?? idx === 3 ? "row-span-1" : "row-span-2"}`}
            >
              <h2 className="text-purple font-medium text-base md:text-xl/[38px]">
                {key}
              </h2>
              <div className="flex flex-col ">
                {val.map((elem, id) => (
                  <Link
                    key={id}
                    href={localizePath(elem.path, locale)}
                    className={`${path.includes(elem.path) ? "text-white" : "text-purple"} mb-[3px] xl:whitespace-nowrap font-normal text-xs/[25px] md:text-base/[30px] hover:text-white`}
                  >
                    {elem.title}
                  </Link>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
